var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.headBtnOptions,
                  "head-title": _vm.$t(
                    `cip.plat.app.attendance.title.indexHeadTitle`
                  ),
                },
                on: {
                  "head-remove": _vm.headRemove,
                  "head-add-tabs": _vm.headAddTabs,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.gridHeadSearch,
          "grid-head-empty": _vm.gridHeadEmpty,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "grid-row-btn": _vm.gridRowBtn,
          "table-options": _vm.tableOptions,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "data-total": _vm.page.total,
          page: _vm.page,
        },
        on: {
          "gird-handle-select-click": _vm.selectionChange,
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
          "grid-row-detail-click": _vm.rowDetail,
          "row-remove": _vm.rowRemove,
          "row-edit": _vm.rowEdit,
        },
        scopedSlots: _vm._u([
          {
            key: "customBtn",
            fn: function ({ row }) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.rowEnable(row)
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        row.status == 2
                          ? _vm.$t("cip.cmn.btn.validBtn")
                          : _vm.$t("cip.cmn.btn.disable")
                      ) + "\r\n      "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }